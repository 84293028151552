import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import { Component, Ref, Watch } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import { ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrCheckoutDevicesView',
  computed: {
    ...mapState('leads', ['checkinCheckoutSettings', 'checkinCheckoutData', 'exhibitorStats']),
    ...mapState('attendee', ['attendees'])
  }
})
export default class GtrLeadsView extends GtrSuper {
  @Ref()
  readonly observerEditParticipant!: InstanceType<typeof ValidationObserver>;

  attendees: any

  data () {
    return {
      dialog: false,
      dialog_notes: false,
      add_device_dialog: false,
      submitting: false,
      loading: false,
      table: {
        search: '',
        headers: [
          { text: 'Status', align: 'start', sortable: false, searchable: false, value: 'onsite_device_status', width: '400' },
          { text: 'Device', align: 'start', sortable: true, value: 'onsite_device', width: '150' },
          { text: 'Name', align: 'start', sortable: true, value: 'full_name', width: '200' },
          { text: 'Company', align: 'start', sortable: true, value: 'company', width: '150' },
          { text: 'Phone Number', align: 'start', sortable: true, value: 'onsite_phone', width: '200' },
          // { text: 'Email', align: 'start', sortable: true, value: 'participant_data.email' },
          { text: 'Booth', align: 'start', sortable: true, value: 'onsite_booth', width: '100' },
          { text: 'Notes', align: 'start', sortable: true, value: 'onsite_notes', width: '200' }
          // { text: 'Activation Codes', align: 'start', sortable: true, value: 'activation_codes' },
          // { text: 'Micro Scanner', align: 'start', sortable: true, value: 'micro_scanner', width: '100' },
          // { text: 'Pro Scanner', align: 'start', sortable: true, value: 'pro_scanner', width: '100' },
          // { text: 'Code Reader', align: 'start', sortable: true, value: 'code_reader', width: '100' }
        ],
        items: []
      },
      devices: [
        {
          text: 'Proscanner',
          value: 'Proscanner'
        }
      ],
      event_uuid: this.$route.params.event_uuid,
      participants: [],
      participant_items: [],
      activeExhibitors: [],
      participant_uuid: null,
      participant_edit: {
        onsite_device_status: null,
        onsite_phone: null,
        onsite_notes: null
      },
      new_device: {
        participant_uuid: null,
        onsite_phone: null,
        proscanner_qty: '0'
      },
      characterLimit: 191
    }
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.LEADS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Leads Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }

  // @Watch('participants')
  // onParticipantChange (payload: any) {
  //   if (payload) {
  //     this.$data.table.items = payload
  //     this.$data.table.items.forEach((item) => {
  //       item.full_name = (item.participant_data.first_name !== null ? item.participant_data.first_name : '') + ' ' +
  //         (item.participant_data.last_name !== null ? item.participant_data.last_name : '')
  //       item.search_data = item.participant_data.company + ' ' + item.participant_data.first_name + ' ' + item.participant_data.last_name + ' ' + item.participant_data.email
  //     })
  //   }
  // }

  private async fetchExhibitorStats () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchExhibitorStats', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private keepTextUnderCharacterLimit (event, ref) {
    setTimeout(() => {
      const text = event.target.value
      if (text.length >= this.$data.characterLimit) {
        (this as any).checkinCheckoutSettings[ref] = text.slice(0, this.$data.characterLimit)
        if ((event.keyCode >= 48 && event.keyCode <= 90) || [8, 32].includes(event.keyCode)) {
          event.preventDefault()
        }
      }
    }, 100)
  }

  @Watch('checkinCheckoutData')
  onCheckinCheckoutData (payload: any) {
    if (payload) {
      this.$data.table.items = payload
      this.$data.table.items.forEach((item) => {
        item.company = (item.participant.participant_data.company !== null ? item.participant.participant_data.company : '')
        item.full_name = (item.participant.participant_data.first_name !== null ? item.participant.participant_data.first_name : '') + ' ' +
          (item.participant.participant_data.last_name !== null ? item.participant.participant_data.last_name : '')
        item.search_data = `${item.participant.participant_data.company} ${item.participant.participant_data.first_name} ${item.participant.participant_data.last_name}  ${item.participant.participant_data.email} ${item.onsite_booth} ${item.onsite_phone} ${item.onsite_device} ${item.onsite_device_status} ${item.onsite_notes}`
      })
    }
  }

  @Watch('dialog')
  onDialogChange (val: any) {
    if (!val) {
      this.clearForm()
    }
  }

  @Watch('dialog_notes')
  onDialogNotesChange (val: any) {
    if (!val) {
      this.clearForm()
    }
  }

  @Watch('add_device_dialog')
  onAddDeviceDialogChange (val: any) {
    if (!val) {
      this.clearForm()
    }
  }

  async mounted () {
    const promises: Promise<any>[] = [this.fetchCheckinCheckoutSettings(), this.fetchCheckinCheckoutData(), this.fetchDevices(), this.fetchExhibitorStats(), this.$store.dispatch('attendee/fetchAttendees', this.$route.params.event_uuid)]
    await Promise.all(promises)
    if (!this.$data?.new_device?.participant_uuid) {
      this.$data.participant_items = this.$data.activeExhibitors
    }
  }

  searchInput (key: string) {
    if (!key) {
      this.$data.participant_items = this.$data.activeExhibitors
    } else {
      const { data: participants = [] } = this.attendees
      this.$data.participant_items = participants.map(p => ({ text: `${p.condensed_data.first_name} ${p.condensed_data.last_name} (${p.condensed_data.email})`, value: p.condensed_data.uuid })).filter(p => p.text.toLowerCase().indexOf(key.toLowerCase()) > -1)
    }
  }

  @Watch('exhibitorStats')
  onExhibitorStatsChange (payload: any) {
    if (payload.length) {
      for (let i = 0; i < payload.length; i++) {
        if ((payload[i].first_name !== null || payload[i].last_name !== null) && payload[i].lr_status === 'Complete') {
          this.$data.activeExhibitors.push({
            value: payload[i].uuid,
            text: (payload[i].first_name !== null ? payload[i].first_name : '') + ' ' +
              (payload[i].last_name !== null ? payload[i].last_name : '')
          })
        }
      }
    }
  }

  getNewDeviceStatus (currentStatus: string): string {
    if (currentStatus === 'CHECKEDIN') {
      return 'CHECKEDOUT'
    }
    return 'CHECKEDIN'
  }

  getDeviceSwitchColor (item) {
    if (this.isDeviceCheckedOut(item)) {
      return '#EF4923'
    }
    return '#006CBB'
  }

  isDeviceCheckedOut (item) {
    if (item.onsite_device_status === 'CHECKEDOUT') {
      return true
    }
    return false
  }

  private editDialog (item) {
    this.$data.participant_edit = {
      onsite_device_status: item.onsite_device_status,
      microscanner_qty: item.microscanner_qty,
      proscanner_qty: item.proscanner_qty,
      onsite_phone: item.onsite_phone,
      onsite_notes: item.onsite_notes,
      onsite_booth: item.onsite_booth
    }
    this.$data.participant_uuid = item.participant.uuid
    this.$data.checkout_uuid = item.uuid
    this.$data.dialog = true
  }

  private editNotesDialog (item) {
    this.$data.participant_edit = {
      onsite_device_status: item.onsite_device_status,
      microscanner_qty: item.microscanner_qty,
      proscanner_qty: item.proscanner_qty,
      onsite_phone: item.onsite_phone,
      onsite_notes: item.onsite_notes,
      onsite_booth: item.onsite_booth
    }
    this.$data.participant_uuid = item.participant.uuid
    this.$data.checkout_uuid = item.uuid
    this.$data.dialog_notes = true
  }

  async submit () {
    await this.updateDeviceStatus()
  }

  async cancel () {
    this.$data.dialog = false
  }

  handleClose () {
    this.clearForm()
    // this.$emit('close')
  }

  handleDeviceDialogClose () {
    this.$data.add_device_dialog = false
  }

  private clearForm () {
    this.$data.participant_edit = {
      onsite_device_status: null,
      onsite_phone: null,
      onsite_notes: null
    }
    this.$data.participant_uuid = null

    this.$data.new_device = {
      participant_uuid: null,
      onsite_phone: null,
      microscanner_qty: '0',
      proscanner_qty: '0'
    }
    this.observerEditParticipant.reset()
  }

  private async fetchCheckinCheckoutSettings (): Promise<void> {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchCheckinCheckoutSettings', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchCheckinCheckoutData (): Promise<void> {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/fetchCheckinCheckoutData', { event_uuid: this.$data.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async updateCheckinCheckoutSettings (): Promise<void> {
    try {
      this.$data.loading = true
      const data = (this as any).checkinCheckoutSettings
      await this.$store.dispatch('leads/updateCheckinCheckoutSettings', {
        event_uuid: this.$data.event_uuid,
        data
      })
      Container.get(Notification).success('Check-in Check-out settings saved successfully.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async fetchDevices () {
    try {
      this.$data.loading = true

      // this.$data.table.items = []
      this.$data.participants = []

      this.$data.participants = this.$data.participants.concat(await this.$store.dispatch('leads/fetchCheckoutDevices',
        { event_uuid: this.$data.event_uuid, device_status: 'CHECKEDIN' }
      ))

      this.$data.participants = this.$data.participants.concat(await this.$store.dispatch('leads/fetchCheckoutDevices',
        { event_uuid: this.$data.event_uuid, device_status: 'CHECKEDOUT' }
      ))
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async updateDeviceStatus () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/updateDeviceStatus',
        { event_uuid: this.$data.event_uuid, participant_uuid: this.$data.participant_uuid, checkout_uuid: this.$data.checkout_uuid, data: this.$data.participant_edit }
      )
      this.$data.dialog = false
      this.$data.dialog_notes = false
      this.$data.loading = false
      this.$data.participant_uuid = null
      this.$data.checkout_uuid = null
      await this.fetchDevices()
      await this.fetchCheckinCheckoutData()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private async updateDeviceStatusIndividualRow (checkout_uuid, participant_uuid, status) {
    try {
      await this.$store.dispatch('leads/updateDeviceStatus',
        { event_uuid: this.$data.event_uuid, participant_uuid, checkout_uuid, data: { onsite_device_status: status } }
      )
      this.$data.loading = true
      this.$data.dialog = false
      this.$data.dialog_notes = false
      this.$data.loading = false
      await this.fetchDevices()
      await this.fetchCheckinCheckoutData()
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    }
  }

  private addDeviceDialog () {
    this.$data.new_device = {
      participant_uuid: null,
      onsite_phone: null,
      microscanner_qty: '0',
      proscanner_qty: '0'
    }
    this.$data.add_device_dialog = true
  }

  private async deviceCheckout () {
    try {
      this.$data.loading = true
      const data = this.$data.new_device
      const participant_uuid = this.$data.new_device.participant_uuid
      delete data.participant_uuid
      await this.$store.dispatch('leads/deviceCheckout',
        { event_uuid: this.$data.event_uuid, participant_uuid, data }
      )
      this.$data.loading = false
      this.$data.add_device_dialog = false
      await this.fetchDevices()
      await this.fetchCheckinCheckoutData()
    } catch (error) {
      this.$data.loading = false
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
  }

  async exportDevicesByEvent () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('leads/getExportDevicesByEvent', this.$route.params.event_uuid)
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  private async sendSMSCheckingReminder () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('leads/sendSMSCheckingReminder',
        { event_uuid: this.$data.event_uuid }
      )
      this.$data.loading = false
      Container.get(Notification).success('Checkin reminder sent successfully')
    } catch (error) {
      this.$data.loading = false
      Container.get(ErrorHandlerService).error(error)
    } finally {
    }
  }
}
